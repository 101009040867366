/* eslint-disable no-alert */
import { useState, useContext } from 'react';
import { motion } from 'framer-motion';
import { Link, Redirect } from 'react-router-dom';
import { Button } from '@material-ui/core';
import { toast } from 'react-toastify';
import { FormContainer, InputControl, Container } from './styles';
import {LogoImg} from '../Logo';
import { MainContext } from '../../context/ApiContext';
import { MaskedTextField, TextField } from '../FormFields';

const RegisterForm = () => {
  const {
    auth: { register },
  } = useContext(MainContext);
  const [redirect, setRedirect] = useState(false);
  const [values, setValues] = useState({
    name: '',
    login: '',
    email: '',
    emailVerify: '',
    password: '',
    passwordVerify: '',
    phone: '',
    observation: '',
    errorMsg: '',
    error: false,
  });

  const handleRegister = async (e) => {
    e.preventDefault();
    // if (values.email !== values.emailVerify) {
    //   alert('O e-mail não coincide');
    // }
    if (values.password !== values.passwordVerify) {
      alert('A senha não coincide');
    }
    else {
      try {
        const newUser = {
          name: values.name,
          login: values.login,
          email: values.email,
          phone: values.phone,
          password: values.password,
          role: 5,
          observation: values.observation,
        };
        await register(newUser);

        toast.success(`Novo Usuário ${newUser.name} registrado com sucesso!`);
        setRedirect(true);
      } catch (error) {
        if (error.response.status === 403) alert('Usuário já cadastrado');
      }
    }
  };

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };

// const phoneMask = (phone) => {
//   if(phone.replace(/[^\d]/g, '').length < 11)
//   return '(99)9999-99999'
//   return '(99)99999-9999'
// }

  return (
    <Container initial={{ scaleY: 0.5, opacity: 1 }} animate={{ scaleY: 1, opacity: 1 }}>
      <LogoImg initial={{ opacity: 0 }} animate={{ opacity: 1 }} />
      <motion.div>
        <FormContainer elevation={1}>
          <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 0.5, delay: 0.2 }}>
            <div className="title-container">
              <h1>Registro de usuário</h1>
            </div>
            <form onSubmit={handleRegister}>
              <InputControl fullWidth required>
                <TextField
                  className="control sm"
                  value={values.name || ''}
                  onChange={handleChange}
                  varibleName="name"
                  label="Nome"
                  placeholder="Seu nome completo"
                  fullWidth
                  required
                />
                <TextField
                  className="control sm"
                  value={values.login || ''}
                  onChange={handleChange}
                  varibleName="login"
                  label="Login"
                  placeholder="Para entrar no M3tas"
                  fullWidth
                  required
                />
                <TextField
                  className="control sm"
                  value={values.email || ''}
                  onChange={handleChange}
                  varibleName="email"
                  label="E-mail"
                  fullWidth
                  required
                />
                <TextField
                  className="control sm"
                  value={values.emailVerify || ''}
                  onChange={handleChange}
                  varibleName="emailVerify"
                  label="Confirmação de e-mail"
                  fullWidth
                  style={{display: 'none'}}
                />
                <TextField
                  className="control sm"
                  value={values.password || ''}
                  onChange={handleChange}
                  varibleName="password"
                  label="Definição de senha"
                  placeholder="Senha de 6 a 18 caracteres"
                  type="password"
                  fullWidth
                  required
                />
                <TextField
                  className="control sm"
                  value={values.passwordVerify || ''}
                  onChange={handleChange}
                  varibleName="passwordVerify"
                  label="Confirmação de senha"
                  type="password"
                  fullWidth
                  required
                />    
                <MaskedTextField
                  mask={
                    values.phone && values.phone.replace(/[^\d]/g, '').length < 11 ? '(99)9999-99999' : '(99)99999-9999'
                  }
                  value={values.phone || ''}
                  onChange={handleChange}
                  varibleName="phone"
                  label="TELEFONE"
                  // disabled={type === 'createUser'}
                  required
                  fullWidth
                />
              </InputControl>
              {/* <MaskedTextField
                mask={phoneMask(values.phone)}
                value={values.phone || ''}
                onChange={handleChange}
                varibleName="phone"
                label="TELEFONE"
                variant="standard"
                fullWidth
                InputLabelProps={{
                  shrink: false,
                }}
                required
              /> */}
              <InputControl fullWidth>
                <TextField
                  value={values.observation}
                  onChange={(e) => setValues({ ...values, observation: e.target.value })}
                  varibleName="observation"
                  label="Observação"
                  multiline
                  rows={2}
                />
              </InputControl>
              <div className="footer">
                <Button className="entrar" variant="contained" type="submit">
                  Cadastrar
                </Button>
                <Button className="back" variant="contained" component={Link} to="/login">
                  Voltar
                </Button>
              </div>
            </form>
          </motion.div>
        </FormContainer>
      </motion.div>
      {redirect && <Redirect to="/login" />}
    </Container>
  );
};

export default RegisterForm;
