import { motion } from 'framer-motion';
import { useState } from 'react';
import { Button } from '@material-ui/core';

import { Header, Container } from '../../styles/shared/styles';
import { SecaoBotoes, SecaoPagina } from './styles';
import ContasPagarReceber from './ContasPagarReceber';
import ContasCorrentes from './ContasCorrentes';
import FechamentoMensal from './FechamentoMensal';
import TabelasFinanceiro from './TabelasFinanceiro';

const Financial = () => {
  const [paginaAtual, setPaginaAtual] = useState('')

  const renderPagina = () => {
    switch (paginaAtual) {
      case 'contas-pagar-receber': return <ContasPagarReceber />
      case 'contas-correntes': return <ContasCorrentes />
      case 'fechamento-mensal': return <FechamentoMensal />
      case 'tabelas-financeiro': return <TabelasFinanceiro />
      case 'controle-parcelas': return <>Controle de Parcelas</>
      case 'recibos-comissao': return <>Recibos de Comissão</>
      case 'notas-fiscais': return <>Notas Fiscais</>
      case 'controle-repasses': return <>Controle de Repasses</>
      default: return <i>Escolha uma opção para abrir a tela correspondente.</i>
    }
  }

  return (<>
    <motion.div initial={{ opacity: 0, x: -100 }} animate={{ opacity: 1, x: 0 }} exit={{ opacity: 0, x: -100 }}>
      <Container>
        <Header>
          <h1>Financeiro</h1>
        </Header>
        <div>
          <SecaoBotoes>
            <Button variant="contained" onClick={() => setPaginaAtual('contas-pagar-receber')}>Contas a Pagar e Receber</Button>
            <Button variant="contained" onClick={() => setPaginaAtual('contas-correntes')}>Contas Correntes</Button>
            <Button variant="contained" onClick={() => setPaginaAtual('fechamento-mensal')}>Fechamento Mensal</Button>
            <Button variant="contained" onClick={() => setPaginaAtual('tabelas-financeiro')}>Tabelas do Financeiro</Button>
            <Button variant="contained" disabled onClick={() => setPaginaAtual('controle-parcelas')}>Controle de Parcelas</Button>
            <Button variant="contained" disabled onClick={() => setPaginaAtual('recibos-comissao')}>Recibos de Comissão</Button>
            <Button variant="contained" disabled onClick={() => setPaginaAtual('notas-fiscais')}>Notas Fiscais</Button>
            <Button variant="contained" disabled onClick={() => setPaginaAtual('controle-repasses')}>Controle de Repasses</Button>
          </SecaoBotoes>
          <SecaoPagina>
            {renderPagina()}
          </SecaoPagina>
        </div>
      </Container>
    </motion.div>
  </>
  )
}

export default Financial;