/* eslint-disable */

// eslint Prop type "array" is forbidden
// rows: PropTypes.array.isRequired 
// mas eh necessario por ser um componente reutilizavel

import { makeStyles, TextField, Toolbar, Tooltip, Typography } from "@material-ui/core";
import PropTypes from "prop-types";
import { useEffect, useRef, useState } from "react";
import { formatCellValue } from "../../../utils/financialHelper";

const EnhancedTableToolbar = ({
  rows,
  setRows,
  title,
  headCells,
  setPage,
  filterValue,
  setFilterValue,
  dadoFoiModificado,
  setDadoFoiModificado,
}) => {

  //____________________________________________
  // Filtro - necessita do useRef e os useEffects que seguem
  const rowsCompletoRef = useRef(rows);

  useEffect(() => {
    if (!dadoFoiModificado) return

    rowsCompletoRef.current = rows
    setDadoFoiModificado(false)

  }, [dadoFoiModificado])

  useEffect(() => {
    if (filterValue?.length < 3) {
      setRows(rowsCompletoRef.current)
      return
    }

    setPage(0); // volta pra primeira página

    const rowsFiltered = rowsCompletoRef.current.filter((item) => (
      headCells.some((column) => {
        const fieldValueFormatted = formatCellValue(item, column)

        if (fieldValueFormatted !== undefined && fieldValueFormatted !== null) {
          return fieldValueFormatted.toString().toLowerCase().includes(filterValue?.toLowerCase() || '');
        }
        return false;
      })
    ))

    setRows(rowsFiltered)
  }, [filterValue])
  //____________________________________________
  // FIM - Filtro

  const useToolbarStyles = makeStyles(() => ({
    root: {
      padding: '0 15px'
    },
    title: {
      flex: '1 1 100%',
      fontWeight: 'bold'
    },
  }));

  const classes = useToolbarStyles();

  return (
    <Toolbar className={classes.root} >
      <Typography className={classes.title} variant="h5" id="tableTitle" component="div">
        {title}
      </Typography>

      <Tooltip title="Filtrar as informações">
        <TextField
          label="Filtrar"
          id="name"
          name='name'
          value={filterValue}
          placeholder='Digite para filtrar ...'
          variant="outlined"
          InputLabelProps={{ shrink: true }}
          onChange={(e) => { setFilterValue(e?.target?.value || '') }}
          size="small"
        />
      </Tooltip>

    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  rows: PropTypes.array.isRequired,
  setRows: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  headCells: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
      field: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
      alignRight: PropTypes.bool.isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
  setPage: PropTypes.func.isRequired,
  filterValue: PropTypes.string.isRequired,
  setFilterValue: PropTypes.func.isRequired,
  dadoFoiModificado: PropTypes.bool.isRequired,
  setDadoFoiModificado: PropTypes.func.isRequired,
}

export default EnhancedTableToolbar;