import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Typography } from "@material-ui/core";
import EnhancedTable from "../../../../components/Financial/EnhancedTable";
import { ACTIONS, COLUMN_TYPE, CONTA_CORRENTE_VALUES_DEFAULT, convertKeysToCamelCase } from "../../../../utils/financialHelper";
import BotaoContainer from "../../../../components/Financial/BotaoContainer/styles";
import FormContasCorrentesCrud from "./FormContasCorrentesCrud";
import CrudDialog from "../../../../components/Financial/CrudDialog";
import api from "../../../../service/api";
import { dataCreate, dataDelete, dataEdit } from "../../../../service/data.service";
import { LoadingButton } from "../../../../components/Loading";

const ContasCorrentesCrud = () => {
  // Exemplos para fins visuais
  const headCells = [
    { key: 'banco', field: 'banco', type: COLUMN_TYPE.STRING, alignRight: false, label: 'Banco' },
    { key: 'agencia', field: 'agencia', type: COLUMN_TYPE.STRING, alignRight: false, label: 'Agência' },
    { key: 'conta', field: 'conta', type: COLUMN_TYPE.STRING, alignRight: false, label: 'Conta' },
    { key: 'descricao', field: 'descricao', type: COLUMN_TYPE.STRING, alignRight: false, label: 'Descricao' },
    { key: 'actions', field: 'actions', type: COLUMN_TYPE.BUTTONS, alignRight: true, label: 'Ações' },
  ];

  // TODO: aplicar o principio em torno do codigo
  const funcionalidade = 'Conta Corrente'

  // TODO: useContext states?
  // useStates
  const [contasCorrentes, setContasCorrentes] = useState([CONTA_CORRENTE_VALUES_DEFAULT])
  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState(`Adicionar ${funcionalidade}`);
  const [isLoading, setIsLoading] = useState(true);
  const [bancos, setBancos] = useState([]); // sera usado na importacao tambem

  // TODO: useContext effects?
  // useEffects
  useEffect(() => {
    let isMounted = true;
    // isMounted resolve o warning: Can't perform a React state update on an unmounted component.

    const fetchBankAccounts = async () => {
      try {
        const response = await api.get('/bank_accounts');
        if (isMounted) {
          const responseData = convertKeysToCamelCase(response.data);
          setContasCorrentes(responseData);
        }
      } catch (error) {
        if (isMounted) {
          toast.error(
            `Erro ao buscar contas a pagar. Favor tentar novamente dentro de alguns instantes. Sinalize o suporte caso persista. Erro técnico: ${error}`
          );
        }
      } finally {
        if (isMounted) {
          setIsLoading(false);
        }
      }
    };

    fetchBankAccounts();

    return () => {
      isMounted = false;
    };
  }, []);

  // TODO: sera utilizado em outros locais os bancosMapeados
  useEffect(() => {
    let isMounted = true;
    // isMounted resolve o warning: Can't perform a React state update on an unmounted component.

    const fetchBanksList = async () => {
      try {
        const response = await api.get('/bank_list');
        const responseData = response.data;
        if (isMounted) {
          const bancosMapeados = responseData.map((data) => ({
            id: data.var_id,
            name: data.value,
            fullName: `${String(data.var_id).padStart(3, '0')} - ${data.value}`
          }));

          setBancos(bancosMapeados)
        }
      } catch (error) {
        if (isMounted)
          toast.error(
            `Erro ao buscar ${funcionalidade}. Favor tentar novamente dentro de alguns instantes. Sinalize o suporte caso persista. Erro técnico: ${error}`
          );
      }
    };

    fetchBanksList();

    return () => {
      isMounted = false;
    };
  }, []);

  // avaliar ficar num local mais generico, talvez no proprio componente
  const [buttonLabel, setButtonLabel] = useState('Adicionar');
  const [action, setAction] = useState(ACTIONS.ADD);
  const [values, setValues] = useState(CONTA_CORRENTE_VALUES_DEFAULT);
  const [filterValue, setFilterValue] = useState('');
  const [dadoFoiModificado, setDadoFoiModificado] = useState(false);

  // custom hook
  const reset = () => {
    setTitle(`Adicionar ${funcionalidade}`);
    setButtonLabel('Adicionar');
    setAction(ACTIONS.ADD);
    setValues(CONTA_CORRENTE_VALUES_DEFAULT);
  };

  const getDadosBanco = (item) => {
    if (!item.id) return item
    return bancos.find((b) => (b.id === item.id))
  }

  const getBankName = (bankCode) => {
    const bancoAchado = bancos.find((banco) => banco.id === bankCode)
    return bancoAchado?.name || ''
  }

  const setContasCorrentesComDadosBanco = (contasCorrentesAtual) => {
    contasCorrentesAtual.map((item) => {

      const dadosBanco = getDadosBanco(item);
      return {
        ...item,
        ...dadosBanco,
      };
    })
  };

  // identico ao useContasAPagar
  const handleOpen = () => {
    setOpen(true);
    setFilterValue('') // para evitar confusão entre o filtro e mostrar os valores atualizados em tela
  };
  const handleClose = () => {
    setOpen(false);
    reset();
  };

  // TODO: generalizar os metodos handleEdit e handleDelete do useContasAPagar - so muda o contasCorrentes, poderia ser o array por parametro - quem sabe um hook useFinancial
  const handleEdit = (id, value) => {
    const contaSelected = contasCorrentes.find((item) => item.id === id);
    if (!contaSelected) return

    setValues(() => ({
      ...contaSelected,
    }));

    setTitle(`Editar ${funcionalidade}`);
    setButtonLabel('Editar');
    setAction(ACTIONS.EDIT);
    handleOpen();
  }

  const handleDelete = (id) => {
    // TODO: mesmo trecho de código do handleEdit, poderia criar outra função apos generalizar os metodos
    const contaSelected = contasCorrentes.find((item) => item.id === id);
    if (!contaSelected) return

    setValues(() => ({
      ...contaSelected,
    }));

    setTitle(`Excluir ${funcionalidade}`);
    setButtonLabel('Excluir');
    setAction(ACTIONS.DELETE);
    handleOpen();
  }

  // TODO: handleSubmit generalizar para reuso
  const handleSubmit = async (e) => {
    e.preventDefault();

    let apiResponse;
    switch (action) {
      case ACTIONS.ADD:
        apiResponse = await dataCreate('/bank_accounts', values);
        if (apiResponse) {
          toast.success(`${funcionalidade} adicionada com sucesso!`)
        }
        
        setContasCorrentes([
          ...contasCorrentes,
          {
            ...values,
            banco: getBankName(values.bankCode),
            id: apiResponse.id
          }
        ]);

        setDadoFoiModificado(true)
        break;

      case ACTIONS.EDIT:
        apiResponse = await dataEdit('/bank_accounts', values, values.id);
        if (apiResponse)
          toast.success(`${funcionalidade} editada com sucesso!`)

        // setContasCorrentesComDadosBanco(contasCorrentes.map((conta) => (
        setContasCorrentes(contasCorrentes.map((conta) => (
          (conta.id === values.id)
            ? { ...conta, ...values, banco: getBankName(values.bankCode) }
            : conta
        )))

        setDadoFoiModificado(true)
        break;

      case ACTIONS.DELETE:
        apiResponse = await dataDelete('/bank_accounts', values.id);
        if (apiResponse)
          toast.success(`${funcionalidade} excluída com sucesso!`)

        setContasCorrentes(contasCorrentes.filter((conta) => conta.id !== values.id));

        setDadoFoiModificado(true)
        break;

      default:
        throw new Error('Escolha uma ação válida dentre as constantes.');
    }

    handleClose();
  }

  return (
    <>
      {
        isLoading ? (
          <Typography variant="h6">
            Carregando {funcionalidade} ...
            <LoadingButton />
          </Typography>
        ) : (
          <>
            <EnhancedTable
              title='Contas Correntes'
              rows={contasCorrentes}
              setRows={setContasCorrentes}
              headCells={headCells}
              handleEdit={handleEdit}
              handleDelete={handleDelete}
              filterValue={filterValue}
              setFilterValue={setFilterValue}
              dadoFoiModificado={dadoFoiModificado}
              setDadoFoiModificado={setDadoFoiModificado}
            />

            <CrudDialog
              open={open}
              title={title}
              buttonLabel={buttonLabel}
              handleClose={handleClose}
              handleSubmit={handleSubmit}
              isDelete={action === ACTIONS.DELETE}
            >
              <FormContasCorrentesCrud
                values={values}
                setValues={setValues}
                bancos={bancos}
              />
            </CrudDialog>

            <BotaoContainer>
              <button type="button" onClick={handleOpen}>Adicionar</button>
            </BotaoContainer>
          </>
        )
      }
    </>
  );
};

export default ContasCorrentesCrud;