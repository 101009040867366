import { InputAdornment, TextField } from "@material-ui/core";
import { NumericFormat } from "react-number-format";
import SelectContaSintetica from "../../../../components/Financial/SelectContaSintetica";
import SelectContaAnalitica from "../../../../components/Financial/SelectContaAnalitica";
import { formatInputValuesToDefault } from "../../../../utils/financialHelper";
import { MaskedTextField } from "../../../../components/FormFields";
import { useContasAPagarContext } from "../../../../context/Financial/ContasAPagarContext";

const FormContasAPagar = () => {
  const { values, setValues, billingAnalytics } = useContasAPagarContext()

  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: formatInputValuesToDefault(value) });
  }

  return (
    <>
      <TextField
        label="Nome"
        id="name"
        name='name'
        value={values.name}
        variant="outlined"
        InputLabelProps={{ shrink: true }}
        onChange={handleChange}
        required
      />
      <MaskedTextField
        mask={
          values?.cpfCnpj && values?.cpfCnpj?.replace(/[^\d]/g, '').length < 12
            ? '999.999.999-999'
            : '99.999.999/9999-99'
        }

        label="CPF/CNPJ"
        id='cpfCnpj'
        name='cpfCnpj'
        value={values.cpfCnpj}
        variant="outlined"
        onChange={handleChange}
        varibleName='cpfCnpj'
        required
      />
      <div className='linha-campos'>
        <SelectContaSintetica onChange={handleChange} />
        <SelectContaAnalitica onChange={handleChange} />
      </div>
      <div className='linha-campos'>
        <NumericFormat
          customInput={TextField}
          label="Valor"
          id='amount'
          name='amount'
          value={values.amount}
          variant="outlined"
          InputProps={{
            startAdornment: <InputAdornment position="start">R$</InputAdornment>,
          }}
          fullWidth
          decimalSeparator=","
          decimalScale={2}
          autoComplete="off"
          onChange={handleChange}
          required
        />
        <TextField
          label="Data de Vencimento"
          id='dueDate'
          name='dueDate'
          value={values.dueDate}
          variant="outlined"
          type='date'
          InputLabelProps={{ shrink: true }}
          fullWidth
          onChange={handleChange}
          required
        />
      </div>
      <div className='linha-campos'>
        <NumericFormat
          customInput={TextField}
          label="Valor Pago"
          id='amountPayed'
          name='amountPayed'
          value={values.amountPayed}
          variant="outlined"
          InputProps={{
            startAdornment: <InputAdornment position="start">R$</InputAdornment>,
          }}
          fullWidth
          decimalSeparator=","
          decimalScale={2}
          autoComplete="off"
          onChange={handleChange}
        />
        <TextField
          label="Data do Pagamento"
          id='paymentDate'
          name='paymentDate'
          value={values.paymentDate}
          variant="outlined"
          type='date'
          InputLabelProps={{ shrink: true }}
          fullWidth
          onChange={handleChange}
        />
      </div>
    </>
  )
};

export default FormContasAPagar;