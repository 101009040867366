import { TableCell, TableHead, TableRow, TableSortLabel } from "@material-ui/core";
import PropTypes from "prop-types";

const EnhancedTableHead = ({ classes, order, orderBy, onRequestSort, headCells }) => {
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.key}
            align={headCell.alignRight ? 'right' : 'left'}
            sortDirection={orderBy === headCell.key ? order : false}
            className={classes.title}
          >
            <TableSortLabel
              active={orderBy === headCell.key}
              direction={orderBy === headCell.key ? order : 'asc'}
              onClick={createSortHandler(headCell.key)}
            >
              {headCell.label}
              {orderBy === headCell.key ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.shape({
    root: PropTypes.string,
    highlight: PropTypes.string,
    title: PropTypes.string,
    table: PropTypes.string,
    paper: PropTypes.string,
    visuallyHidden: PropTypes.string,
  }).isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  headCells: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
      field: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
      alignRight: PropTypes.bool.isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired
};

export default EnhancedTableHead;