import styled from 'styled-components';

const CrudDialogForm = styled.form`
  margin-top: 30px;
  position: relative;
  background-color: #e7e7e7;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 8px;

  h3 {
    margin-bottom: 16px;
  }

  div.Mui-focused fieldset {
    border-color: #f26522 !important;
  }

  label.Mui-focused {
    color: #f26522 !important;
  }

  div.linha-campos {
    display: flex;
    gap: 8px;
  }
`;

export default CrudDialogForm