import styled from 'styled-components';

export const SecaoBotoes = styled.section`
  padding: 0 0 16px;

  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
`;

export const SecaoPagina = styled.section`
  ul,
  li {
    margin: 0;
    padding: 0;
  }

  li {
    list-style-type: none;
  }
`;

export const FuncionalidadeContainer = styled.div`
  *:focus {
    outline: none;
  }

  display: flex;
  flex-direction: column;

  .negrito {
    font-weight: bold;
  }

  h2 {
    margin-top: 24px;
  }

  .acoes {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: 8px;

    button {
      background-color: transparent;
      border: none;
      cursor: pointer;

      :hover {
        color: #f26522;
      }
    }
  }
`;
