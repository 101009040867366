import PropTypes from "prop-types";
import { MenuItem, TextField } from "@material-ui/core"
import { useContasAPagarContext } from "../../../context/Financial/ContasAPagarContext";

const SelectContaAnalitica = ({ onChange }) => {
  const { billingAnalytics, values } = useContasAPagarContext()

  return (
    <TextField
      id='billingAnalytic'
      label="Conta Analítica"
      name='billingAnalytic'
      value={values.billingAnalytic || ''}
      variant="outlined"
      select
      InputLabelProps={{ shrink: true }}
      fullWidth
      onChange={onChange}
    >
      {billingAnalytics.map((option) => (
        <MenuItem
          key={option.id}
          value={option.id}
        >
          {option.name}
        </MenuItem>
      ))}
    </TextField>
  )
}

SelectContaAnalitica.propTypes = {
  onChange: PropTypes.func.isRequired,
};

export default SelectContaAnalitica;