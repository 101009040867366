import PropTypes from 'prop-types';
import { forwardRef, useState, useEffect } from "react";
import { AppBar, Button, Dialog, FormGroup, FormLabel, IconButton, InputLabel, Slide, TextField, Toolbar, Typography } from "@material-ui/core";
import { toast } from 'react-toastify';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import CloseIcon from '@material-ui/icons/Close';
import CrudDialogForm from '../../../../components/Financial/CrudDialogForm';
import BotaoContainer from '../../../../components/Financial/BotaoContainer/styles';

const Transition = forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const ImportarDialog = ({
  open,
  title,
  handleClose,
  handleSubmit,
  setArquivoSelecionado,
}) => {

  const [fileName, setFileName] = useState('');

  const handleFileChange = (event) => {

    const fileSelected = event.target.files[0];

    if (!fileSelected) return false;

    if (fileSelected.size > 2000000) {
      setArquivoSelecionado(null);
      toast.warning('Tamanho máximo suportado de 2Mb.');
    }

    setArquivoSelecionado(fileSelected);
    setFileName(fileSelected.name);
    return '';
  };

  useEffect(() => {
    if (!open) {
      setFileName('');
    }
  }, [open]);

  const width = '350px';

  return (
    <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>

      <AppBar style={{ backgroundColor: '#fa9c12' }}>
        <Toolbar style={{ display: 'flex', justifyContent: 'flex-end', minHeight: '30px' }}>
          <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close" size="small">
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>

      <CrudDialogForm onSubmit={handleSubmit}>
        <h3>{title}</h3>
        <FormGroup style={{ gap: '16px' }}>
          <div className='linha-campos' style={{ flexDirection: 'column', alignItems: 'center', gap: '8px' }}>
            <Typography variant="subtitle1" style={{ margin: '0' }}>
              Selecione o arquivo OFX
            </Typography>

            <Button
              id='ofxFile'
              onChange={handleFileChange}
              component="label"
              variant="contained"
              color="default"
              startIcon={<CloudUploadIcon />}
              className="ofx-file-upload"
              style={{ width, marginBottom: '24px' }}
            >
              Enviar Arquivo OFX
              <input type="file" accept=".ofx" hidden />
            </Button>

            <TextField
              label="Arquivo selecionado"
              value={fileName || 'Nenhum arquivo selecionado'}
              variant="outlined"
              InputLabelProps={{ shrink: true }}
              style={{ width }}
              disabled
            />

          </div>

        </FormGroup>

        <BotaoContainer>
          <button type="submit">
            Importar
          </button>
        </BotaoContainer>

      </CrudDialogForm>

    </Dialog>
  )
};

ImportarDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  setArquivoSelecionado: PropTypes.func.isRequired,
};

export default ImportarDialog;