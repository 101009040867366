import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { motion } from 'framer-motion';
import Grid from '@material-ui/core/Grid';
import { Button, TextField } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import { Autocomplete } from '@material-ui/lab';
import { DateTime } from 'luxon';
import adm from '../../service/adm';
import Filter from '../../components/Filter';
import { Header, Container } from '../../styles/shared/styles';


const GlobalResources = () => {
    const a = 1;

    return (
        <>
        <motion.div initial={{ opacity: 0, x: -100 }} animate={{ opacity: 1, x: 0 }} exit={{ opacity: 0, x: -100 }}>
            <Container>
                <Header>
                <h1 style={{color: '#555'}}>Recursos Globais</h1>
                </Header>
            </Container>
        </motion.div>
        </>
    )
}

export default GlobalResources;