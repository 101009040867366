import { logout } from '../auth.service';
import Login from '../../pages/Login';

const RBMSeguros = () =>
{    
    localStorage.setItem("tenantId", "rbmseguros");
    localStorage.setItem("logoVertical", "logorbmseguros");
    logout();
    
    return Login();
};

export default RBMSeguros;