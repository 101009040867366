import PropTypes from "prop-types"
import { MenuItem, TextField } from "@material-ui/core"
import { formatInputValuesToDefault } from "../../../../utils/financialHelper"

const FormContasCorrentesCrud = ({ values, setValues, bancos }) => {
  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: formatInputValuesToDefault(value) });
  }

  return (
    <>
      <TextField
        id='bankCode'
        label="Banco"
        name='bankCode'
        value={values.bankCode || ''}
        variant="outlined"
        select
        InputLabelProps={{ shrink: true }}
        fullWidth
        onChange={handleChange}
        required
      >
        {bancos.map((option) => (
          <MenuItem
            key={option.id}
            value={option.id}
          >
            {option.fullName}
          </MenuItem>
        )
        )}
      </TextField>
      <div className='linha-campos'>
        <TextField
          label="Agência"
          id='agencia'
          name='agencia'
          value={values.agencia}
          variant="outlined"
          InputLabelProps={{ shrink: true }}
          onChange={handleChange}
          required
          fullWidth
        />
        <TextField
          label="Conta"
          id='conta'
          name='conta'
          value={values.conta}
          variant="outlined"
          InputLabelProps={{ shrink: true }}
          onChange={handleChange}
          required
          fullWidth
        />
      </div>
      <TextField
        label="Descrição"
        id="descricao"
        name='descricao'
        value={values.descricao}
        variant="outlined"
        InputLabelProps={{ shrink: true }}
        onChange={handleChange}
      />
    </>
  )
}

FormContasCorrentesCrud.propTypes = {
  values: PropTypes.shape({
    id: PropTypes.number,
    banco: PropTypes.string.isRequired,
    bankCode: PropTypes.number,
    agencia: PropTypes.string.isRequired,
    conta: PropTypes.string.isRequired,
    descricao: PropTypes.string.isRequired,
    actions: PropTypes.string,
  }).isRequired,
  setValues: PropTypes.func.isRequired,
  bancos: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      fullName: PropTypes.string.isRequired,
    })
  ).isRequired,
}

export default FormContasCorrentesCrud