/**
 * Retorna o status de uma conta a pagar.
 * Na lógica de comparação foi necessário adicionar o timezone T00:00 e converter para a mesma hora
 * para evitar que se a conta estiver a vencer no próprio dia não dê falso atraso em razão da diferença
 * das horas.
 *
 * @param {Object} item - O item da conta a pagar.
 * @param {number} item.amountPayed - O valor pago da conta.
 * @param {string} item.dueDate - A data de vencimento da conta.
 * @returns {string} - O status da conta ('Pago', 'Atrasado' ou 'Pendente').
 */
export const getBillingPayableStatus = (item) => {
  if (item.amountPayed) return 'Pago';

  const dueDate = new Date(`${item.dueDate}T00:00`).setHours(0, 0, 0, 0);
  const today = new Date().setHours(0, 0, 0, 0);
  const isOverdue = today > dueDate;
  return isOverdue ? 'Atrasado' : 'Pendente';
};

/**
 * Converte uma string de snake_case para camelCase.
 *
 * @param {string} str - A string em snake_case.
 * @returns {string} - A string convertida para camelCase.
 * // Exemplo de uso:
 * const str = 'nome_completo';
 * const result = toCamelCase(str);
 * // result => 'nomeCompleto'
 */
const toCamelCase = (str) => str.replace(/_([a-z])/g, (match, letter) => letter.toUpperCase());

/**
 * Converte as chaves de um array de objetos de snake_case para camelCase.
 *
 * @param {Array<Object>} arr - O array de objetos com chaves em snake_case.
 * @returns {Array<Object>} - O array de objetos com chaves convertidas para camelCase.
 *
 * @example
 * // Exemplo de uso:
 * const data = [{ id: 1, nome_completo: 'Fulano de Tal' }];
 * const result = convertKeysToCamelCase(data);
 * // result => [{ id: 1, nomeCompleto: 'Fulano de Tal' }]
 */
export const convertKeysToCamelCase = (arr) =>
  arr.map((obj) =>
    Object.entries(obj).reduce((acc, [key, value]) => {
      const newKey = toCamelCase(key);
      acc[newKey] = value;
      return acc;
    }, {})
  );

/**
 * Retorna a ação de um item.
 * Através da ação chama-se os métodos dataCreate, dataEdit, dataDelete da api.
 *
 * @typedef {Object} ACTIONS
 * @property {string} ADD - Represents the 'add' action.
 * @property {string} EDIT - Represents the 'edit' action.
 * @property {string} DELETE - Represents the 'delete' action.
 */
export const ACTIONS = {
  ADD: 'add',
  DELETE: 'delete',
  EDIT: 'edit',
  VIEW: 'view',
};

/**
 * Tipos de colunas para usar em operações de comparação, filtro e formatação.
 *
 * @typedef {Object} COLUMN_TYPE
 * @property {string} BUTTONS - Tipo de coluna para botões.
 * @property {string} DATE - Tipo de coluna para datas.
 * @property {string} MONEY - Tipo de coluna para valores monetários.
 * @property {string} STRING - Tipo de coluna para strings.
 */
export const COLUMN_TYPE = {
  BUTTONS: 'buttons',
  DATE: 'date',
  MONEY: 'money',
  NUMBER: 'number',
  STRING: 'string',
};

/**
 * Formata uma string de data para o padrão pt-BR.
 *
 * @param {string} dateString - A string da data a ser formatada.
 * @returns {string} - A data formatada no padrão pt-BR (dd/mm/yyyy).
 */
export const formatDate = (dateString) => {
  const date = new Date(dateString);
  date.setMinutes(date.getMinutes() + date.getTimezoneOffset()); // Adjust for timezone
  return date.toLocaleDateString('pt-BR', { day: '2-digit', month: '2-digit', year: 'numeric' });
};

/**
 * Formata o valor de uma célula da tabela EnhancedTable conforme seu tipo.
 *
 * @param {Object} item - O item da célula.
 * @param {Object} column - A coluna da célula.
 * @param {string} column.field - O campo da coluna.
 * @param {string} column.type - O tipo da coluna ('date', 'money', etc.).
 * @returns {string|number} - O valor formatado da célula.
 */
export const formatCellValue = (item, column) => {
  const value = item[column.field];

  switch (column.type) {
    case COLUMN_TYPE.DATE:
      return formatDate(value);

    case COLUMN_TYPE.MONEY:
      return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(value);

    default:
      return value;
  }
};

/**
 * Formata valores de entrada, substituindo vírgulas por pontos em números decimais.
 *
 * @param {string|number} value - O valor a ser formatado.
 * @returns {string|number} - O valor formatado.
 *
 * Exemplos:
 *  formatValue("0,01")        // "0.01"
 *  formatValue("0,1")         // "0.1"
 *  formatValue("10,99")       // "10.99"
 *  formatValue("12341234")    // "12341234"
 *  formatValue("texto")       // "texto"
 *  formatValue("2025-01-01")  // "2025-01-01"
 *  formatValue(123)           // 123
 *  formatValue(null)          // null
 */
const valueIsInMoneyFormat = (value) => typeof value === 'string' && /^-?\d+(,\d+)?$/.test(value);

/** Tratamento e conversão de valores digitados usando a máscara correta para o formato padrão para armazenamento em banco.
 *
 * @param {string|number} value - O valor a ser formatado.
 * @returns {string|number} - O valor formatado.
 *
 */
export const formatInputValuesToDefault = (value) => {
  if (valueIsInMoneyFormat(value)) return value.replace(',', '.');
  return value;
};

/**
 * Valores padrão para uma conta a pagar.
 *
 * @typedef {Object} CONTAS_A_PAGAR_VALUES_DEFAULT
 * @property {number|undefined} id - O ID da conta.
 * @property {string} name - O nome da conta.
 * @property {string} cpfCnpj - O CPF ou CNPJ associado à conta.
 * @property {string} billingSynthetic - A descrição sintética da conta.
 * @property {string} billingAnalytic - A descrição analítica da conta.
 * @property {string} amount - O valor da conta.
 * @property {string} dueDate - A data de vencimento da conta.
 * @property {string} amountPayed - O valor pago da conta.
 * @property {string} paymentDate - A data de pagamento da conta.
 */
export const CONTAS_A_PAGAR_VALUES_DEFAULT = {
  id: undefined,
  name: '',
  cpfCnpj: '',
  billingSynthetic: '',
  billingAnalytic: '',
  amount: '',
  dueDate: '',
  amountPayed: '',
  paymentDate: '',
};

/**
 * Default values for a bank account (Conta Corrente).
 * Valores padrão para uma conta corrente.
 *
 * @typedef {Object} CONTA_CORRENTE_VALUES_DEFAULT
 * @property {number | undefined} id - The unique identifier for the account, initially undefined.
 * @property {string} banco - The name of the bank, default is "Banco do Brasil".
 * @property {number | undefined} bankCode - The bank code, default is "001".
 * @property {string} agencia - The agency number, default is "1111-5".
 * @property {string} conta - The account number, default is "67890-1".
 * @property {string} descricao - A description for the account, default is "Conta BB Principal".
 */
export const CONTA_CORRENTE_VALUES_DEFAULT = {
  id: undefined,
  bankCode: undefined,
  banco: '',
  agencia: '',
  conta: '',
  descricao: '',
};

/**
 * Default values for an OFX file.
 * Valores padrão para um arquivo OFX.
 *
 * @typedef {Object} ARQUIVO_OFX_VALUES_DEFAULT
 * @property {string} routingNumber - The bank routing number.
 * @property {string} accountNumber - The bank account number.
 * @property {string} balanceDate - The balance date.
 * @property {string} interval - The date interval.
 * @property {string} transactionsQty - The number of transactions.
 * @property {string} fileName - The name of the file.
 */
export const ARQUIVO_OFX_VALUES_DEFAULT = {
  routingNumber: '',
  accountNumber: '',
  balanceDate: '',
  interval: '',
  transactionsQty: '',
  fileName: '',
}
