import { Route } from 'react-router-dom';
import { Container } from '@material-ui/core';
import SampleClient from "../service/Tenants/SampleClient";
import M3tas from '../service/Tenants/M3tas';
import RDCorretora from '../service/Tenants/RDCorretora';
import Coruja from '../service/Tenants/Coruja';
import RBMSeguros from '../service/Tenants/RBMSeguros';
import Default from '../service/Tenants/Default';

export const TenantRoutes = () => 
(
    <Container>
        <Route path="/default" component={Default} />
        <Route path="/m3tas" component={M3tas} />
        <Route path="/sampleclient" component={SampleClient} />
        <Route path="/rdgo" component={RDCorretora} />
        <Route path="/coruja" component={Coruja} />
        <Route path="/rbmseguros" component={RBMSeguros} />
    </Container>
);

export function GetTenantRoute() 
{
    const tenantId = localStorage.getItem("tenantId");

    if (tenantId) {
        const tenantRote = localStorage.getItem("tenantId")
            .replace("rdcorretora", "rdgo");
        return tenantRote;
    }

    return "default";
}


