import PropTypes from 'prop-types';
import { forwardRef, useEffect, useState } from "react";
import { AppBar, Dialog, FormGroup, IconButton, Slide, Toolbar } from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import BotaoContainer from '../BotaoContainer/styles';
import CrudDialogForm from '../CrudDialogForm';
import OrangeSwitch from '../OrangeSwitch';
import FormControlLabelRightAlign from '../FormControlLabelRightAlign';

const Transition = forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const CrudDialog = ({
  children,
  open,
  title,
  buttonLabel,
  handleClose,
  handleSubmit,
  isDelete,
}) => {

  const [btnSubmitDisabled, setBtnSubmitDisabled] = useState(false)
  useEffect(() => {
    setBtnSubmitDisabled(isDelete)
  }, [isDelete])

  const handleCheckBoxDelete = (e) => {
    if (e.target.checked)
      setBtnSubmitDisabled(false)
    else
      setBtnSubmitDisabled(true)
  }

  return (
    <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>

      <AppBar style={{ backgroundColor: '#fa9c12' }}>
        <Toolbar style={{ display: 'flex', justifyContent: 'flex-end', minHeight: '30px' }}>
          <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close" size="small">
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>

      <CrudDialogForm onSubmit={handleSubmit}>
        <h3>{title}</h3>
        <FormGroup style={{ gap: '16px' }}>
          {children}
        </FormGroup>

        {isDelete && (
          <FormControlLabelRightAlign
            label="Estou ciente que a exclusão é uma operação irreversível!"
            control={<OrangeSwitch checked={!btnSubmitDisabled} onChange={handleCheckBoxDelete} />}
          />
        )}

        <BotaoContainer>
          <button type="submit" id="btnSubmitForm" disabled={btnSubmitDisabled}>
            {buttonLabel}
          </button>
        </BotaoContainer>

      </CrudDialogForm>

    </Dialog>
  )
};

CrudDialog.propTypes = {
  children: PropTypes.node.isRequired,
  open: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  buttonLabel: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  isDelete: PropTypes.bool.isRequired,
};

export default CrudDialog;