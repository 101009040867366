import PropTypes from "prop-types";
import { MenuItem, TextField } from "@material-ui/core"
import { useContasAPagarContext } from "../../../context/Financial/ContasAPagarContext";

const SelectContaSintetica = ({ onChange }) => {
  const { billingSynthetics, values } = useContasAPagarContext()

  return (
    <TextField
      id='billingSynthetic'
      label="Conta Sintética"
      name='billingSynthetic'
      value={values.billingSynthetic || ''}
      variant="outlined"
      select
      InputLabelProps={{ shrink: true }}
      fullWidth
      onChange={onChange}
    >
      {billingSynthetics.map((option) => (
        <MenuItem
          key={option.id}
          value={option.id}
        >
          {option.name}
        </MenuItem>
      ))}
    </TextField>
  )
}

SelectContaSintetica.propTypes = {
  onChange: PropTypes.func.isRequired
}

export default SelectContaSintetica;