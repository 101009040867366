import { Switch, withStyles } from "@material-ui/core";

const OrangeSwitch = withStyles({
  root: {
    textAlign: 'right'
  },
  switchBase: {
    color: '#e0e0e0',
    '&$checked': {
      color: '#f26522',
    },
    '&$checked + $track': {
      backgroundColor: '#f26522',
    },
  },
  checked: {},
  track: {},
})(Switch);

export default OrangeSwitch;