import ContasAPagar from './ContasAPagar';
import ContasAReceber from './ContasAReceber';
import ImportarArquivoOFX from './ImportarArquivoOFX';
import { FuncionalidadeContainer } from "../styles";

const ContasPagarReceber = () => (
  <>
    <FuncionalidadeContainer>
      <ContasAPagar />
    </FuncionalidadeContainer>
    <FuncionalidadeContainer>
      <ContasAReceber />
    </FuncionalidadeContainer>
    <FuncionalidadeContainer>
      <ImportarArquivoOFX />
    </FuncionalidadeContainer>
  </>
);

export default ContasPagarReceber;