import React from 'react';
import FinancialTable from '../../../../components/Financial/FinancialTable';
import BotaoContainer from '../../../../components/Financial/BotaoContainer/styles';

const ContasAReceber = () => {
  const columns = [
    { field: 'nome', headerName: 'Nome' },
    { field: 'dataVencimento', headerName: 'Data de Vencimento' },
    { field: 'valor', headerName: 'Valor', align: 'right' },
  ];

  const contasAReceber = [
    {
      id: 1,
      nome: 'Cliente 1',
      valor: 215.70,
      dataVencimento: '10/12/2024',
    }, {
      id: 2,
      nome: 'Cliente 2',
      valor: 53.48,
      dataVencimento: '10/12/2024',
    }, {
      id: 3,
      nome: 'Cliente 3',
      valor: 89.90,
      dataVencimento: '10/01/2025',
    }
  ]

  return (
    <div>
      <h2>Contas a Receber</h2>
      <FinancialTable data={contasAReceber} columns={columns} totalLabel="Total de contas" />
      <BotaoContainer>
        <button type="button">Adicionar</button>
      </BotaoContainer>
    </div>
  );
};

export default ContasAReceber;