import { logout } from '../auth.service';
import Login from '../../pages/Login';

const RDCorretora = () =>
{    
    localStorage.setItem("tenantId", "rdcorretora");
    localStorage.setItem("logoVertical", "logordcorretora");
    logout();
    
    return Login();
};

export default RDCorretora;