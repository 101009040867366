import { useState } from "react"
import PropTypes from "prop-types"
import { TextField } from "@material-ui/core"
import { COLUMN_TYPE, formatInputValuesToDefault } from "../../../../utils/financialHelper"
import EnhancedTable from "../../../../components/Financial/EnhancedTable"

const FormImportarArquivoOFX = ({ values, setValues }) => {
  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: formatInputValuesToDefault(value) });
  }

  const headCellsTransaction = [
    { key: 'type', field: 'type', type: COLUMN_TYPE.STRING, alignRight: false, label: 'Tipo' },
    { key: 'date', field: 'date', type: COLUMN_TYPE.DATE, alignRight: false, label: 'Data' },
    { key: 'amount', field: 'amount', type: COLUMN_TYPE.MONEY, alignRight: true, label: 'Valor' },
    { key: 'memo', field: 'memo', type: COLUMN_TYPE.STRING, alignRight: false, label: 'Descrição' },
    { key: 'checkNumber', field: 'checkNumber', type: COLUMN_TYPE.STRING, alignRight: false, label: 'Identificador' },
  ];

  const [filterValue, setFilterValue] = useState('');
  const [dadoFoiModificado, setDadoFoiModificado] = useState(false);
  const [transactions, setTransactions] = useState(values.transactions);

  return (
    <>
      <div className='linha-campos'>
        <TextField
          label="Banco"
          id='routingNumber'
          name='routingNumber'
          value={values.routingNumber}
          variant="outlined"
          InputLabelProps={{ shrink: true }}
          onChange={handleChange}
          disabled
          fullWidth
        />
        <TextField
          label="Conta"
          id='accountNumber'
          name='accountNumber'
          value={values.accountNumber}
          variant="outlined"
          InputLabelProps={{ shrink: true }}
          onChange={handleChange}
          disabled
          fullWidth
        />
        <TextField
          label="Arquivo"
          id='fileName'
          name='fileName'
          value={values.fileName}
          variant="outlined"
          InputLabelProps={{ shrink: true }}
          onChange={handleChange}
          disabled
          fullWidth
        />
      </div>
      <div className='linha-campos'>
        <TextField
          label="Emitido em"
          id='balanceDate'
          name='balanceDate'
          value={values.balanceDate}
          variant="outlined"
          InputLabelProps={{ shrink: true }}
          onChange={handleChange}
          disabled
          fullWidth
        />
        <TextField
          label="Período"
          id='interval'
          name='interval'
          value={values.interval}
          variant="outlined"
          InputLabelProps={{ shrink: true }}
          onChange={handleChange}
          disabled
          fullWidth
        />
        <TextField
          label="Importado em"
          id='createdAt'
          name='createdAt'
          value={new Date(values.createdAt).toLocaleString()}
          variant="outlined"
          InputLabelProps={{ shrink: true }}
          onChange={handleChange}
          disabled
          fullWidth
        />
      </div>
      <div className='linha-campos'>
        <TextField
          label="Tipo"
          id='accountType'
          name='accountType'
          value={values.accountType}
          variant="outlined"
          InputLabelProps={{ shrink: true }}
          onChange={handleChange}
          disabled
          fullWidth
        />
        <TextField
          label="ID Balanço"
          id='balance'
          name='balance'
          value={values.balance}
          variant="outlined"
          InputLabelProps={{ shrink: true }}
          onChange={handleChange}
          disabled
          fullWidth
        />
        <TextField
          label="ID Transação"
          id='transactionUid'
          name='transactionUid'
          value={values.transactionUid}
          variant="outlined"
          InputLabelProps={{ shrink: true }}
          onChange={handleChange}
          disabled
          fullWidth
        />
      </div>
      <div className='linha-campos'>
        <EnhancedTable
          title='Arquivos OFX'
          rows={transactions}
          setRows={setTransactions}
          headCells={headCellsTransaction}
          handleEdit={null}
          handleDelete={null}
          filterValue={filterValue}
          setFilterValue={setFilterValue}
          dadoFoiModificado={dadoFoiModificado}
          setDadoFoiModificado={setDadoFoiModificado}
        />
      </div>
    </>
  )
}

FormImportarArquivoOFX.propTypes = {
  values: PropTypes.shape({
    routingNumber: PropTypes.string || undefined,
    accountNumber: PropTypes.string || undefined,
    fileName: PropTypes.string || undefined,
    balanceDate: PropTypes.string || undefined,
    interval: PropTypes.string || undefined,
    accountType: PropTypes.string || undefined,
    balance: PropTypes.string || undefined,
    transactionUid: PropTypes.string || undefined,
    createdAt: PropTypes.string || undefined,
    transactions: PropTypes.arrayOf(
      PropTypes.shape({
        type: PropTypes.string.isRequired,
        date: PropTypes.string.isRequired,
        amount: PropTypes.string.isRequired,
        memo: PropTypes.string.isRequired,
        checkNumber: PropTypes.string.isRequired,
      })
    ) || undefined,
  }).isRequired,
  setValues: PropTypes.func.isRequired,
}

export default FormImportarArquivoOFX