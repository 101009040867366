import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { motion } from 'framer-motion';
import Grid from '@material-ui/core/Grid';
import { Button, TextField } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import { Autocomplete } from '@material-ui/lab';
import { DateTime } from 'luxon';
import adm from '../../service/adm';
import Filter from '../../components/Filter';
import { Header, Container } from '../../styles/shared/styles';

const M3tasResources = () => {
    const [filters, setFilters] = useState({
        initDate: DateTime.now().toISODate(),
        endDate: DateTime.now().toISODate(),
        lead: null,
        cleanLeadOption: null,
        client: null,
    });
    const cleanLeadOptionsList = [{id: 1, name: 'Soft Delete'}, {id : 2, name: 'Apagar'}];

    const handleFilter = () => {

    }

    const handleClear = () => {
        setFilters({
            initDate: DateTime.now().toISODate(),
            endDate: DateTime.now().toISODate(),
            lead: null,
            cleanLeadOption: null,
            client: null,
        });
    }; 

    const handleChange = (e) => {
        setFilters({ ...filters, [e.target.name]: e.target.value });
    };

    const handleClearLeads = async () => {
        try {
            const response = await adm.post(`/deleteleads`, {
                initDate: filters.initDate,
                endDate: filters.endDate,
                lead: filters.lead,
                cleanLeadOption: filters.cleanLeadOption?.id,
            });
            
            if (response.status == 200) {
                alert('Limpeza dos Leads realizada!');
                toast.warning(response.data);
            }
        }
        catch (error) {
            alert(error.errorInfo);
            toast.error(error);
        }
    }

    const handleClearClients = async () => {
        try {
            const response = await adm.post(`/deleteclients`, {
                initDate: filters.initDate,
                endDate: filters.endDate,
                client: filters.client,
            });
            
            if (response.status == 200) {
                alert('Limpeza dos clientes realizada!');
                toast.warning(response.data);
            }
        }
        catch (error) {
            alert(error.errorInfo);
            toast.error(error);
        }
    }


    return (
        <>
        <motion.div initial={{ opacity: 0, x: -100 }} animate={{ opacity: 1, x: 0 }} exit={{ opacity: 0, x: -100 }}>
            <Container>
                <Header>
                <h1>Recursos M3tas</h1>
                </Header>

                <Filter
                    buttonStyle={{"height": "fit-content"}}
                    clickFilter={handleFilter}
                    clearFilter={handleClear}
                >
                <Grid container spacing={2}>
                <label>
                    Client Id
                    <input
                    type="number"
                    name="client"
                    className="input-form"
                    title='Cliente'
                    value={filters.client}
                    onChange={handleChange}
                    />
                </label>
                <label>
                    Lead
                    <input
                    type="number"
                    name="lead"
                    className="input-form"
                    title='Lead'
                    value={filters.lead}
                    onChange={handleChange}
                    />
                </label>
                <label>
                    Data início:
                    <input
                    type="date"
                    name="initDate"
                    className="input-form"
                    title='De'
                    value={filters.initDate}
                    onChange={handleChange}
                    />
                </label>

                <label>
                    Data fim:
                    <input
                    type="date"
                    name="endDate"
                    className="input-form"
                    title='Até'
                    value={filters.endDate}
                    onChange={handleChange}
                    />
                </label>

                </Grid>
                </Filter>

                <br />
                <Divider />
                <br />

                <div>
                <Autocomplete
                    xs={4}
                    disableClearable
                    options={cleanLeadOptionsList || [1]}
                    getOptionLabel={(option) => option?.name || [1]}
                    value={filters.cleanLeadOption || [1]}
                    onChange={(e, value) => setFilters({...filters, cleanLeadOption: value})}
                    renderInput={(params) => (
                        <TextField {...params} 
                        className="autocomplete" 
                        variant="standard" 
                        label="Opções" 
                        />
                    )}
                    style={{
                        width: '200px'
                    }}
                />
                <br />
                <Button    
                    variant="contained"
                    onClick={handleClearLeads}>
                    Limpar Leads
                </Button>

                <Button    
                    variant="contained"
                    onClick={handleClearClients}>
                    Limpar Clientes
                </Button>
                </div>

                <br />
                <Divider />
                <br />

                <Link to='/companies-group'>
                    Grupo de Empresas
                </Link> 

                </Container>
            </motion.div>
        </>
    );
};

    
export default M3tasResources;
