import styled from "styled-components";

const BotaoContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-left: 4px;
  padding-right: 4px;
  padding: 8px 0 0;

  button {
    cursor: pointer;
    color: #fff;
    border: none;
    font-weight: 700;
    padding: 8px 16px;
    text-align: center;
    border-radius: 8px;
    white-space: nowrap;
    display: inline-block;
    vertical-align: middle;
    background-color: #f26522;
    text-transform: uppercase;
    font-family: 'DM Sans', sans-serif;

    :hover {
      box-shadow: inset 0 0 0 1px white;
    }

    :disabled {
      background-color: #e0e0e0;
      color: rgba(0, 0, 0, 0.87);
      box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
      cursor: not-allowed;
    }
  }
`;

export default BotaoContainer;