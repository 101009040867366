import React from 'react';
import PropTypes from 'prop-types';
import { Paper, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TableRow } from "@material-ui/core";
import { Delete, Edit } from '@material-ui/icons';

const FinancialTable = ({ data, columns, totalLabel, handleEdit, handleDelete }) => {
  const totalValor = data.reduce((sum, item) => sum + Number(item.amount), 0);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    date.setMinutes(date.getMinutes() + date.getTimezoneOffset()); // Adjust for timezone
    return date.toLocaleDateString('pt-BR', { day: '2-digit', month: '2-digit', year: 'numeric' });
  };

  const formatCellValue = (item, column) => {
    if (column.field === 'amount') {
      return item[column.field]?.toLocaleString(undefined, { style: 'currency', currency: 'BRL' });
    }
    if (column.field === 'due_date') {
      return formatDate(item.dueDate);
    }
    return item[column.field];
  };

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            {columns.map((column) => (
              <TableCell key={column.field} className='negrito' align={column.align || 'left'}>
                {column.headerName}
              </TableCell>
            ))}
            <TableCell className='negrito' align='right'>
              Ações
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((item) => (
            <TableRow key={item.id}>
              {columns.map((column) => (
                <TableCell key={column.field} align={column.align || 'left'}>
                  {formatCellValue(item, column)}
                </TableCell>
              ))}
              <TableCell className='acoes' align='right'>
                <button type='button' onClick={() => handleEdit(item.id)}><Edit /></button>
                <button type='button' onClick={() => handleDelete(item.id)}><Delete /></button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TableCell colSpan={columns.length - 1}>
              {totalLabel}: {data.length}
            </TableCell>
            <TableCell align='right'>
              {totalValor.toLocaleString(undefined, { style: 'currency', currency: 'BRL' })}
            </TableCell>
            <TableCell />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
};

FinancialTable.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      nome: PropTypes.string.isRequired,
      valor: PropTypes.number.isRequired,
      dataVencimento: PropTypes.string.isRequired,
      status: PropTypes.string,
    })
  ).isRequired,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      field: PropTypes.string.isRequired,
      headerName: PropTypes.string.isRequired,
      align: PropTypes.string,
    })
  ).isRequired,
  totalLabel: PropTypes.string.isRequired,
  handleEdit: PropTypes.func,
  handleDelete: PropTypes.func,
};

FinancialTable.defaultProps = {
  handleEdit: () => {},
  handleDelete: () => {},
}

export default FinancialTable;