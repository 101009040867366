import { createContext, useContext, useEffect, useState } from 'react';
import P from 'prop-types';
import { toast } from 'react-toastify';
import {
  ACTIONS,
  CONTAS_A_PAGAR_VALUES_DEFAULT,
  convertKeysToCamelCase,
  getBillingPayableStatus,
} from '../../utils/financialHelper';
import api from '../../service/api';

const ContasAPagarContext = createContext();

export const ContasAPagarProvider = ({ children }) => {
  // useStates
  const [contasAPagar, setContasAPagar] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [filterValue, setFilterValue] = useState('');
  const [values, setValues] = useState(CONTAS_A_PAGAR_VALUES_DEFAULT);
  const [dadoFoiModificado, setDadoFoiModificado] = useState(false);
  const [title, setTitle] = useState('Adicionar Conta a Pagar');
  const [buttonLabel, setButtonLabel] = useState('Adicionar');
  const [billingTypes, setBillingTypes] = useState([]);
  const [billingAnalytics, setBillingAnalytics] = useState([]);
  const [billingSynthetics, setBillingSynthetics] = useState([]);
  const [open, setOpen] = useState(false);
  const [action, setAction] = useState(ACTIONS.ADD);

  // custom setters
  const setContasAPagarComStatus = (contasAPagarAtual) => {
    setContasAPagar(
      contasAPagarAtual.map((item) => {
        const status = getBillingPayableStatus(item);
        return {
          ...item,
          status,
        };
      })
    );
  };

  // useEffects
  useEffect(() => {
    let isMounted = true; // Warning: Can't perform a React state update on an unmounted component. This is a no-op, but it indicates a memory leak in your application. To fix, cancel all subscriptions and asynchronous tasks in a useEffect cleanup function. Error Component Stack

    const fetchBillingPayables = async () => {
      try {
        const response = await api.get('/billings_payable');
        if (isMounted) {
          const responseData = convertKeysToCamelCase(response.data);
          setContasAPagarComStatus(responseData);
        }
      } catch (error) {
        if (isMounted) {
          toast.error(
            `Erro ao buscar contas a pagar. Favor tentar novamente dentro de alguns instantes. Sinalize o suporte caso persista. Erro técnico: ${error}`
          );
        }
      } finally {
        if (isMounted) {
          setIsLoading(false);
        }
      }
    };

    fetchBillingPayables();

    return () => {
      isMounted = false;
    };
  }, []);

  useEffect(() => {
    let isMounted = true; // Warning: Can't perform a React state update on an unmounted component. This is a no-op, but it indicates a memory leak in your application. To fix, cancel all subscriptions and asynchronous tasks in a useEffect cleanup function. Error Component Stack

    const fetchBillingTypes = async () => {
      try {
        const response = await api.get('/billing_types');
        if (isMounted) setBillingTypes(response.data);
      } catch (error) {
        if (isMounted)
          toast.error(
            `Erro ao buscar tipos de contas a pagar. Favor tentar novamente dentro de alguns instantes. Sinalize o suporte caso persista. Erro técnico: ${error}`
          );
      }
    };

    fetchBillingTypes();

    return () => {
      isMounted = false;
    };
  }, []);

  useEffect(() => {
    if (billingTypes?.billing_analytics?.length > 0) {
      setBillingAnalytics(
        billingTypes.billing_analytics.map((item) => ({
          id: item.var_id,
          name: item.value,
        }))
      );
      setBillingSynthetics(
        billingTypes.billing_synthetics.map((item) => ({
          id: item.var_id,
          name: item.value,
        }))
      );
    }
  }, [billingTypes]);

  // provider do contexto
  return (
    <ContasAPagarContext.Provider
      value={{
        contasAPagar,
        setContasAPagar,
        setContasAPagarComStatus,
        isLoading,
        setIsLoading,
        filterValue,
        setFilterValue,
        values,
        setValues,
        dadoFoiModificado,
        setDadoFoiModificado,
        title,
        setTitle,
        buttonLabel,
        setButtonLabel,
        billingTypes,
        setBillingTypes,
        billingAnalytics,
        setBillingAnalytics,
        billingSynthetics,
        setBillingSynthetics,
        open,
        setOpen,
        action,
        setAction,
      }}>
      {children}
    </ContasAPagarContext.Provider>
  );
};

// hook para usar o contexto
export const useContasAPagarContext = () => {
  const context = useContext(ContasAPagarContext);
  if (!context) {
    throw new Error('useContasAPagarContext deve ser usado dentro de um ContasAPagarProvider');
  }
  return context;
};

ContasAPagarProvider.propTypes = {
  children: P.node.isRequired,
};
