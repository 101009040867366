import { toast } from 'react-toastify';
import { ACTIONS, CONTAS_A_PAGAR_VALUES_DEFAULT, convertKeysToCamelCase, getBillingPayableStatus } from '../utils/financialHelper';
import { dataCreate, dataDelete, dataEdit } from '../service/data.service';
import { useContasAPagarContext } from '../context/Financial/ContasAPagarContext';

const useContasAPagar = () => {
  const {
    contasAPagar,
    setContasAPagarComStatus,
    isLoading,
    filterValue,
    setFilterValue,
    values,
    setValues,
    dadoFoiModificado,
    setDadoFoiModificado,
    title,
    setTitle,
    buttonLabel,
    setButtonLabel,
    billingAnalytics,
    billingSynthetics,
    open,
    setOpen,
    action,
    setAction,
  } = useContasAPagarContext();

  const reset = () => {
    setTitle('Adicionar Conta a Pagar');
    setButtonLabel('Adicionar');
    setAction(ACTIONS.ADD);
    setValues(CONTAS_A_PAGAR_VALUES_DEFAULT);
  };

  const handleOpen = () => {
    setOpen(true);
    setFilterValue('') // para evitar confusão entre o filtro e mostrar os valores atualizados em tela
  };
  const handleClose = () => {
    setOpen(false);
    reset();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      let apiResponse;
      switch (action) {
        case ACTIONS.ADD:
          apiResponse = await dataCreate('/billings_payable', values);
          if (apiResponse) {
            toast.success('Conta a pagar adicionada com sucesso!');
          }

          setContasAPagarComStatus([...contasAPagar, { ...values, id: apiResponse.id }]);
          
          setDadoFoiModificado(true)
          break;

        case ACTIONS.EDIT:
          apiResponse = await dataEdit('/billings_payable', values, values.id);
          if (apiResponse) {
            toast.success('Conta editada com sucesso!');
          }

          setContasAPagarComStatus(
            contasAPagar.map((conta) => (conta.id === values.id ? { ...conta, ...values } : conta))
          );

          setDadoFoiModificado(true)
          break;

        case ACTIONS.DELETE:
          apiResponse = await dataDelete('/billings_payable', values.id);
          if (apiResponse) {
            toast.success('Conta excluída com sucesso!');
          }

          setContasAPagarComStatus(contasAPagar.filter((conta) => conta.id !== values.id));

          setDadoFoiModificado(true)
          break;

        default:
          throw new Error('Escolha uma ação válida dentre as constantes.');
      }
    } catch (error) {
      toast.error(`Erro ao adicionar conta a pagar. Favor tentar novamente dentro de alguns instantes. Sinalize o suporte caso persista. Erro técnico: ${error}`);
    }

    handleClose();
  };

  const handleEdit = (id) => {
    const contaSelected = contasAPagar.find((item) => item.id === id);
    if (!contaSelected) return;

    setValues(() => ({
      ...contaSelected,
    }));
    setTitle('Editar Conta a Pagar');
    setButtonLabel('Editar');
    setAction(ACTIONS.EDIT);
    handleOpen();
  };

  const handleDelete = (id) => {
    const contaSelected = contasAPagar.find((item) => item.id === id);
    if (!contaSelected) return;

    setValues(() => ({
      ...contaSelected,
    }));
    setTitle('Excluir Conta a Pagar');
    setButtonLabel('Excluir Conta');
    setAction(ACTIONS.DELETE);
    handleOpen();
  };

  return {
    handleOpen,
    handleClose,
    handleSubmit,
    handleEdit,
    handleDelete,
  };
};

export default useContasAPagar;