import React, { useEffect, useState } from 'react';
import { Paper, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TableRow, Typography } from "@material-ui/core";
import { toast } from 'react-toastify';
import BotaoContainer from '../../../../components/Financial/BotaoContainer/styles';
import { ACTIONS, ARQUIVO_OFX_VALUES_DEFAULT, COLUMN_TYPE, convertKeysToCamelCase } from '../../../../utils/financialHelper';
import api from '../../../../service/api';
import { LoadingButton } from '../../../../components/Loading';
import EnhancedTable from '../../../../components/Financial/EnhancedTable';
import CrudDialog from '../../../../components/Financial/CrudDialog';
import FormImportarArquivoOFX from './FormImportarArquivoOFX';
import { dataCreate, dataDelete } from '../../../../service/data.service';
import ImportarDialog from './ImportarDialog';

const ImportarArquivoOFX = () => {

  const headCells = [
    { key: 'routingNumber', field: 'routingNumber', type: COLUMN_TYPE.STRING, alignRight: false, label: 'Banco' },
    { key: 'accountNumber', field: 'accountNumber', type: COLUMN_TYPE.STRING, alignRight: false, label: 'Conta' },
    { key: 'balanceDate', field: 'balanceDate', type: COLUMN_TYPE.STRING, alignRight: false, label: 'Emitido em' },
    { key: 'interval', field: 'interval', type: COLUMN_TYPE.STRING, alignRight: false, label: 'Período' },
    { key: 'transactionsQty', field: 'transactionsQty', type: COLUMN_TYPE.NUMBER, alignRight: false, label: 'Transações' },
    { key: 'fileName', field: 'fileName', type: COLUMN_TYPE.STRING, alignRight: false, label: 'Arquivo' },
    { key: 'actions', field: 'actions', type: COLUMN_TYPE.BUTTONS, alignRight: true, label: 'Ações' },
  ];

  const funcionalidade = 'Arquivo OFX';

  const [arquivosOFX, setArquivosOFX] = useState([]);
  const [open, setOpen] = useState(false);
  const [openImportar, setOpenImportar] = useState(false);
  const [title, setTitle] = useState(`Importar ${funcionalidade}`);
  const [isLoading, setIsLoading] = useState(true);
  const [values, setValues] = useState(ARQUIVO_OFX_VALUES_DEFAULT);
  const [filterValue, setFilterValue] = useState('');
  const [dadoFoiModificado, setDadoFoiModificado] = useState(false);
  const [buttonLabel, setButtonLabel] = useState('Importar');
  const [action, setAction] = useState(ACTIONS.ADD);
  const [arquivoSelecionado, setArquivoSelecionado] = useState('');

  const ajustarCamposCustomizadosOfxFile = (ofx) => {
    const balanceDate = new Date(ofx.balanceDate);
    const interval = `${new Date(ofx.startDate).toLocaleDateString()} - ${new Date(ofx.endDate).toLocaleDateString()}`
    const transactionsQty = ofx.transactions.length;

    return {
      ...ofx,
      balanceDate: balanceDate.toLocaleDateString(),
      interval,
      transactionsQty,
      transactions: convertKeysToCamelCase(ofx.transactions)
    }
  }

  const fetchOfxFiles = async () => {
    setIsLoading(true);
    try {
      const response = await api.get('/ofx');
      let ofxFile = convertKeysToCamelCase(response.data);
      ofxFile = ofxFile.map((ofx) => (ajustarCamposCustomizadosOfxFile(ofx)));
      setArquivosOFX(ofxFile);

    } catch (error) {
      toast.error(
        `Erro ao buscar ${funcionalidade}. Favor tentar novamente dentro de alguns instantes. Sinalize o suporte caso persista. Erro técnico: ${error}`
      );
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchOfxFiles();
  }, []);

  // TODO customHook
  const reset = () => {
    setTitle(`Importar ${funcionalidade}`); // TODO tableTitle
    setButtonLabel('Importar');
    setAction(ACTIONS.ADD);
    setValues(ARQUIVO_OFX_VALUES_DEFAULT);
  };

  // TODO useFinancial: identico ao useContasAPagar
  const handleOpen = () => {
    setOpen(true);
    setFilterValue('') // para evitar confusão entre o filtro e mostrar os valores atualizados em tela
  };
  const handleClose = () => {
    setOpenImportar(false);
    setOpen(false);
    reset();
  };

  // TODO useFinancial: generalizar os metodos handleEdit e handleDelete do useContasAPagar - so muda o contasCorrentes, poderia ser o array por parametro - quem sabe um hook useFinancial
  const handleView = (id, value) => {
    const arquivoOfxSelected = arquivosOFX.find((item) => item.id === id);
    if (!arquivoOfxSelected) return

    setValues(() => ({
      ...arquivoOfxSelected,
    }));

    setTitle(`Visualizar ${funcionalidade}`);
    setButtonLabel('Fechar');
    setAction(ACTIONS.VIEW);
    handleOpen();
  }

  const handleDelete = (id) => {
    // TODO: mesmo trecho de código do handleEdit, poderia criar outra função apos generalizar os metodos
    const arquivoOfxSelected = arquivosOFX.find((item) => item.id === id);
    if (!arquivoOfxSelected) return

    setValues(() => ({
      ...arquivoOfxSelected,
    }));

    setTitle(`Excluir ${funcionalidade}`);
    setButtonLabel('Excluir');
    setAction(ACTIONS.DELETE);
    handleOpen();
  }

  const handleImportar = () => {
    setOpenImportar(true);
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    switch (action) {
      case ACTIONS.ADD: {
        if (!arquivoSelecionado) {
          toast.error('Selecione um arquivo OFX para importar.');
          return;
        }

        const formData = new FormData();
        formData.append('ofx-file', arquivoSelecionado);

        try {
          const apiResponse = await api.post('/import-ofx', formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
              'File-Name': 'ofx-file'
            },
          });

          if (apiResponse) toast.success(`${funcionalidade} importado com sucesso!`);

          await fetchOfxFiles() // recarrega a listagem do backend

        } catch (error) {
          toast.error(`Erro ao enviar arquivo. Favor tentar novamente dentro de alguns instantes. Sinalize o suporte caso persista. Erro técnico: ${error} - ${error?.response?.data?.error}`);
        }

        break;
      }

      case ACTIONS.VIEW:
        break;

      case ACTIONS.DELETE: {
        const apiResponse = await dataDelete('/ofx', values.id);
        if (apiResponse)
          toast.success(`${funcionalidade} excluído com sucesso!`)

        setArquivosOFX(arquivosOFX.filter((arquivo) => arquivo.id !== values.id));

        setDadoFoiModificado(true)
        break;
      }

      default:
        throw new Error('Escolha uma ação válida dentre as constantes.');
    }

    handleClose();
  }

  return (
    <>
      {
        isLoading ? (
          <Typography variant="h6">
            Carregando {funcionalidade} ...
            <LoadingButton />
          </Typography>
        ) : (
          <>
            <EnhancedTable
              title='Arquivos OFX'
              rows={arquivosOFX} // FIXME: parece que tem 1px de diferença na altura da linha do rodapé
              setRows={setArquivosOFX}
              headCells={headCells}
              handleView={handleView}
              handleDelete={handleDelete}
              filterValue={filterValue}
              setFilterValue={setFilterValue}
              dadoFoiModificado={dadoFoiModificado}
              setDadoFoiModificado={setDadoFoiModificado}
            />
            <CrudDialog
              open={open}
              title={title}
              buttonLabel={buttonLabel}
              handleClose={handleClose}
              handleSubmit={handleSubmit}
              isDelete={action === ACTIONS.DELETE}
            >
              <FormImportarArquivoOFX
                values={values}
                setValues={setValues}
              />
            </CrudDialog>

            <ImportarDialog
              open={openImportar}
              title={title}
              handleClose={() => { setOpenImportar(false); }}
              handleSubmit={handleSubmit}
              setArquivoSelecionado={setArquivoSelecionado}
            />

            <BotaoContainer>
              <button type="button" onClick={handleImportar}>Importar OFX</button>
            </BotaoContainer>
          </>
        )
      }
    </>
  );
}

export default ImportarArquivoOFX;