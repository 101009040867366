import { FuncionalidadeContainer } from "../styles"
import ContasCorrentesCrud from "./ContasCorrentesCrud"
import Fornecedores from "./Fornecedores"
import TiposDeImpostos from "./TiposDeImpostos"

const ContasCorrentes = () => (
  <>
    <FuncionalidadeContainer>
      <ContasCorrentesCrud />
    </FuncionalidadeContainer>
    <FuncionalidadeContainer>
      <Fornecedores />
    </FuncionalidadeContainer>
    <FuncionalidadeContainer>
      <TiposDeImpostos />
    </FuncionalidadeContainer>
  </>
)

export default ContasCorrentes