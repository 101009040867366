import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { motion } from 'framer-motion';
import Grid from '@material-ui/core/Grid';
import { Button, TextField } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import { Autocomplete } from '@material-ui/lab';
import { DateTime } from 'luxon';
import adm from '../../service/adm';
import Filter from '../../components/Filter';
import { Header, Container } from '../../styles/shared/styles';
import { AutocompleteField } from '../../components/FormFields';
import CompTable from '../../components/CompTable';


const CompaniesGroup = () => {
    const [values, setValues] = useState({
        company: [],
        allCompanies: [],
        companiesGroup: null,
    });

    useEffect(async () => { 
        const companies = await adm.get(`/companies`);
        setValues({allCompanies: companies.data.data});
     }, []);

     const colums = [
        { titulo: 'ID', variavel: 'id' },
        { titulo: 'Grupo', variavel: 'tenantGroup' },
        { titulo: 'Empresa', variavel: 'tenant' },
    ];     

    const handleAutoCompleteChange = (e, newValue, variable) => {
        setValues({
            ...values,
            [variable]: newValue,
          });
    }

    const handleConsultar = async () => {
        const companiesGroup = await adm.get(`/companiesGroup/${values.company.tenant}`);
        setValues({...values, companiesGroup: companiesGroup.data.data});
    }


    return (
        <>
        <motion.div initial={{ opacity: 0, x: -100 }} animate={{ opacity: 1, x: 0 }} exit={{ opacity: 0, x: -100 }}>
            <Container>
                <Header>
                <h1>Grupo de Empresas</h1>
                </Header>

                <Grid>
                    <AutocompleteField
                        className="control g"
                        valueText={values.company || []}
                        options={values.allCompanies || []}
                        getOptionLabel={(option) => option?.name}
                        value={values.company || []}
                        varibleName="company"
                        onChange={handleAutoCompleteChange}
                        label="Empresas"
                        style={{width: '200px'}}
                    />
                    <br/>
                    <Button
                        onClick={handleConsultar}
                        variant="contained"
                        color="default">
                        Abrir
                    </Button>

                    <CompTable data={values.companiesGroup} colums={colums} cellOnClick={()=>{}}/>
                    
                </Grid>
            </Container>
        </motion.div>
        </>
    )
}

export default CompaniesGroup;