/* eslint-disable no-unused-vars */
import { useState } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { FormGroup, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { toast } from 'react-toastify';
import P from 'prop-types';
import { AutocompleteField } from '../FormFields';
import { PhoneMask, RemoveMask } from '../../utils/phoneMask';
import api from '../../service/api';
import { LoadingButton } from '../Loading';
import { GetWhatsAppProvider, GetwhatsAppProviderApiKey, ProviderType, GetLiveChat,
         PostCreateMsgFlow, PostDeactivateRemider } 
  from '../../service/whatsApp.service.ts';

export default function CallModal({ open, setOpen, clientName, clientPhones, broker, callLabel, callType, clientId }) {
  const [values, setValues] = useState({
    extension: '',
  });

  const entering = () => {
    setValues({
      ...values,
      extension: '',
    });
  };

  const setCreateRemider = (value) => {
    setValues({
      ...values,
      loadingButton_createRemider: value,
    });
  };

  const setDeactivateRemider = (value) => {
    setValues({
      ...values,
      loadingButton_deactivateRemider: value,
    });
  };

  const handleClose = () => {
    setCreateRemider(false);
    setOpen(false);
  };

  const submit = async (e) => {
    e.preventDefault();

    // const valueDTO = {
    //   extension: RemoveMask(values.phone.name),
    //   user: broker.login,
    // };

    // try 
    // {
    //   const res = await api.post(`/vsphone`, valueDTO);
    //   if (res) {
    //     handleClose();
    //   }
    // } catch (error) {
    //   toast.error(error.response.data.mensagem, { position: 'top-center' });
    // }
  };


  function GetWhatsAppLiveChat() {
    window.open(GetLiveChat(values.phone.name), "_blank");
  }

  function GetWhatsAppWeb() {
    window.open("https://web.whatsapp.com/", "_blank");
  }

  const CreateRemider = async (e) => {
    if (values.loadingButton_createRemider) return;

    setCreateRemider(true);
    PostCreateMsgFlow(values.phone.name, 
      clientId,
      () => handleClose());
  }

  const DeactivateRemider = async (e) => {
    if (values.loadingButton_deactivateRemider) return;

    setDeactivateRemider(true);
    PostDeactivateRemider(values.phone.name, 
      clientId,
      () => handleClose());
  }

  const whatsAppSubmit = async (e) => {
    e.preventDefault();

    switch(GetWhatsAppProvider()){
      case ProviderType.ToTalk:
        GetWhatsAppLiveChat();
        break;
      case ProviderType.Web:
        GetWhatsAppWeb();
        break;
      default:
        break;
    }
  }

  const handleChangeAutoComplete = (event, newValue, variable) => {
    if (newValue) {
      setValues({
        ...values,
        [variable]: newValue,
      });
    }
  };  

  const clientPhonesOptions = Array.from([
    { name: clientPhones[0], id: 1 },
    { name: clientPhones[1], id: 2 },
  ]);

  return (
    <div>
      <Dialog
        onEntering={entering}
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        fullWidth
        maxWidth="sm">
        <DialogTitle id="form-dialog-title" style={{ backgroundColor: '#fc986a' }}>
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}>
            {callLabel} {" "} {clientName}
            <IconButton edge="end" color="inherit" onClick={handleClose} aria-label="close">
              <CloseIcon />
            </IconButton>
          </div>
        </DialogTitle>
        <form onSubmit={callType == 1 ? submit : whatsAppSubmit}>
          <DialogContent>
            <FormGroup>
              <AutocompleteField
                valueText={values.phone ? values.phone.name : ''}
                options={clientPhonesOptions}
                value={values.phone || null}
                varibleName="phone"
                onChange={handleChangeAutoComplete}
                label="Telefone de contato"
                required
              />
            </FormGroup>
          </DialogContent>
          <DialogActions>
            <Button type="submit" color="primary">
            {callLabel?.split(" ")[0]}
            </Button>

            { GetWhatsAppProvider() == ProviderType.ToTalk &&
              (
                <>
                  <Button color="primary" onClick={CreateRemider}>
                    {values.loadingButton_createRemider ? 
                      (
                        <LoadingButton />
                      ) : 
                      (<></>) 
                    }                    
                    Criar Lembrete
                  </Button>
                  <Button color="primary" onClick={DeactivateRemider}>
                    {values.loadingButton_deactivateRemider ? 
                      (
                        <LoadingButton />
                      ) : 
                      (<></>) 
                    }                    
                    Desativar Lembrete
                  </Button>
                </>
              )
            }

            <Button onClick={handleClose} color="primary">
              Cancelar
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
}

CallModal.propTypes = {
  open: P.bool.isRequired,
  setOpen: P.func.isRequired,
  clientName: P.string,
  clientPhones: P.arrayOf(P.string),
  broker: P.objectOf(P.string).isRequired,
  callLabel: P.string,
  callType: P.number,
  clientId: P.number,
};

CallModal.defaultProps = {
  clientName: 'Cliente',
  clientPhones: ['', ''],
  callLabel: "Ligar Para",
  callType: 1,
  clientId: 0,
};

