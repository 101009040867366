/* eslint-disable */

// eslint Prop type "array" is forbidden
// rows: PropTypes.array.isRequired 
// mas eh necessario por ser um componente reutilizavel

import { makeStyles, Paper, Table, TableBody, TableCell, TableContainer, TablePagination, TableRow } from "@material-ui/core";
import { useEffect, useState } from "react";
import { Delete, Edit, OpenInBrowser } from "@material-ui/icons";
import PropTypes from "prop-types";
import EnhancedTableToolbar from "./EnhancedTableToolbar";
import EnhancedTableHead from "./EnhancedTableHead";
import { COLUMN_TYPE, formatCellValue } from "../../../utils/financialHelper";

const EnhancedTable = ({
  title,
  rows,
  setRows,
  headCells,
  handleEdit,
  handleDelete,
  handleView,
  filterValue,
  setFilterValue,
  dadoFoiModificado,
  setDadoFoiModificado,
}) => {
  const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
    },
    paper: {
      width: '100%',
    },
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
    title: {
      fontWeight: 'bold'
    }
  }));

  const classes = useStyles();
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('calories');
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    setPage(0);
  }, [dadoFoiModificado])

  const descendingComparator = (a, b, orderB) => {
    if (b[orderB] < a[orderB]) {
      return -1;
    }
    if (b[orderB] > a[orderB]) {
      return 1;
    }
    return 0;
  }

  // BUG: decrescente numérico negativo não está funcionando
  function getComparator(orderA, orderB) {
    return orderA === 'desc'
      ? (a, b) => descendingComparator(a, b, orderB)
      : (a, b) => -descendingComparator(a, b, orderB);
  }

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const sortOrder = comparator(a[0], b[0]);
      if (sortOrder !== 0) return sortOrder;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <EnhancedTableToolbar
          numSelected={selected.length}
          rows={rows}
          setRows={setRows}
          title={title}
          headCells={headCells}
          setPage={setPage}
          filterValue={filterValue}
          setFilterValue={setFilterValue}
          dadoFoiModificado={dadoFoiModificado}
          setDadoFoiModificado={setDadoFoiModificado}
        />
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
              headCells={headCells}
            />
            <TableBody>
              {stableSort(rows, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => (
                  <TableRow
                    hover
                    tabIndex={-1}
                    key={row.id}
                  >
                    {headCells && headCells.map((column) => (
                      column.type !== COLUMN_TYPE.BUTTONS && (
                        <TableCell key={`${column.key}_${row.id}`} align={column.alignRight ? 'right' : 'left'}>
                          {formatCellValue(row, column)}
                        </TableCell>
                      )
                    ))}

                    {(handleEdit || handleDelete || handleView) && (
                      <TableCell key={`actions_${row.id}`} className='acoes' align='right'>
                        {handleView && <button type='button' onClick={() => handleView(row.id)}><OpenInBrowser /></button>}
                        {handleEdit && <button type='button' onClick={() => handleEdit(row.id)}><Edit /></button>}
                        {handleDelete && <button type='button' onClick={() => handleDelete(row.id)}><Delete /></button>}
                      </TableCell>
                    )}

                  </TableRow>
                )
                )
              }
              {emptyRows > 0 && (
                <TableRow>
                  <TableCell colSpan={headCells ? headCells.length : 10} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
}

EnhancedTable.propTypes = {
  title: PropTypes.string.isRequired,
  rows: PropTypes.array.isRequired,
  setRows: PropTypes.func.isRequired,
  headCells: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
      field: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
      alignRight: PropTypes.bool.isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
  handleEdit: PropTypes.func || undefined,
  handleDelete: PropTypes.func || undefined,
  handleView: PropTypes.func || undefined,
  filterValue: PropTypes.string.isRequired,
  setFilterValue: PropTypes.func.isRequired,
  dadoFoiModificado: PropTypes.bool.isRequired,
  setDadoFoiModificado: PropTypes.func.isRequired,
}

EnhancedTable.defaultProps = {
  handleEdit: undefined,
  handleDelete: undefined,
  handleView: undefined
}

export default EnhancedTable;