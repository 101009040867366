import { Typography } from '@material-ui/core';
import CrudDialog from '../../../../components/Financial/CrudDialog';
import BotaoContainer from '../../../../components/Financial/BotaoContainer/styles';
import { ACTIONS, COLUMN_TYPE } from '../../../../utils/financialHelper';
import FormContasAPagar from './FormContasAPagar';
import useContasAPagar from '../../../../hooks/useContasAPagar';
import EnhancedTable from '../../../../components/Financial/EnhancedTable';
import { useContasAPagarContext } from '../../../../context/Financial/ContasAPagarContext';
import { LoadingButton } from '../../../../components/Loading';

const ContasAPagar = () => {
  const headCells = [
    { key: 'name', field: 'name', type: COLUMN_TYPE.STRING, alignRight: false, label: 'Nome' },
    { key: 'dueDate', field: 'dueDate', type: COLUMN_TYPE.DATE, alignRight: true, label: 'Data de Vencimento' },
    { key: 'status', field: 'status', type: COLUMN_TYPE.STRING, alignRight: true, label: 'Status' },
    { key: 'amount', field: 'amount', type: COLUMN_TYPE.MONEY, alignRight: true, label: 'Valor' },
    { key: 'actions', field: 'actions', type: COLUMN_TYPE.BUTTONS, alignRight: true, label: 'Ações' },
  ];

  const {
    open,
    title,
    buttonLabel,
    contasAPagar,
    setContasAPagarComStatus,
    isLoading,
    action,
    filterValue,
    setFilterValue,
    dadoFoiModificado,
    setDadoFoiModificado,
  } = useContasAPagarContext()

  const {
    handleOpen,
    handleEdit,
    handleDelete,
    handleClose,
    handleSubmit,
  } = useContasAPagar()

  return (
    <>
      {
        isLoading ? (
          <Typography variant="h6">
            Carregando contas a pagar...
            <LoadingButton />
          </Typography>
        ) : (
          <>
            <EnhancedTable
              title='Contas a Pagar'
              rows={contasAPagar}
              setRows={setContasAPagarComStatus}
              headCells={headCells}
              handleEdit={handleEdit}
              handleDelete={handleDelete}
              filterValue={filterValue}
              setFilterValue={setFilterValue}
              dadoFoiModificado={dadoFoiModificado}
              setDadoFoiModificado={setDadoFoiModificado}
            />

            <CrudDialog
              open={open}
              title={title}
              buttonLabel={buttonLabel}
              handleClose={handleClose}
              handleSubmit={handleSubmit}
              isDelete={action === ACTIONS.DELETE}
            >
              <FormContasAPagar />
            </CrudDialog>

            <BotaoContainer>
              <button type="button" onClick={handleOpen}>Adicionar</button>
            </BotaoContainer>
          </>
        )
      }
    </>
  );
};

export default ContasAPagar;